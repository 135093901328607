import { lazy } from "react";

export const UserLoginForm = lazy(() => import("./Login"));
export const UserForgotForm = lazy(() => import("./Forgot"));
export const UserResetPasswordForm = lazy(() => import("./ResetPassword"));
export const UserSignUpForm = lazy(() => import("./SignUp"));
export const CheckoutFormOne = lazy(() => import("./CheckoutFormOne"));
export const CheckoutFormTwo = lazy(() => import("./CheckoutFormTwo"));
export const CheckoutFormThree = lazy(() => import("./CheckoutFormThree"));
export const TripFilter = lazy(() => import("./TripFilter"));
export const UserEditProfileForm = lazy(() => import("./EditProfile"));
export const UserChangePasswordForm = lazy(() =>
  import("./ChangePasswordForm/index.form")
);
export const UserContactUsForm = lazy(() => import("./UserContactUsForm/index.form"))
